import React, {useEffect, useState} from "react";
import {Center} from "react-containers";
import {FormGroup, InputGroup, MenuItem, PanelStack, PanelStack2} from "@blueprintjs/core";
import axios from "axios";
import {Select} from "@blueprintjs/select";
import QRCode from "react-qr-code";
import * as moment from "moment";
import "moment/locale/ru";
import {Card, Form, Dropdown, Button, CardGroup} from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

moment.locale("ru");

function App() {
    const [paymentLink, setPaymentLink] = useState("");
    const [amount, setAmount] = useState(0);

    const getDays = (days) => {
        if (days >= 30) {
            return {days: 1, type: 'M'};
        } else {
            return {days, type: 'd'};
        }
    };

    const getPrice = (client, days) => {
        if (days >= 30) {
            return client.prices['30'];
        } else if (days < 7) {
            return client.prices['1'] * days;
        } else {
            return client.prices['7'];
        }
    };

    const Panel1 = props => {
        return <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <h3>{props.order.amount} руб.</h3>
            <p>{props.order.client.name} {props.order.client.city}</p>
            <p>Дней: {props.order.days}</p>
            <Button style={{width: "300px"}}
                    onClick={() => window.open(props.order.paymentLink, "_blank")}>Оплатить</Button>
        </div>
    };

    const PanelClient = props => {
        const [client, setClient] = useState({});
        const [loadResult, setLoadResult] = useState("loading");

        const getData = () => {
            axios.post("/api/clients/openapi/where", {
                where: {
                    name: localStorage.getItem("name"),
                    id: Number(localStorage.getItem("id")),
                }
            }).then(data => {
                setClient(data.data[0]);
                setLoadResult("ok");
            })
        };

        useEffect(() => {
            if (loadResult === "loading") {
                getData();
            }
        }, [loadResult]);

        return <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
            {
                loadResult === "ok"
                    ? <div className="centeredCard">
                        <br/>
                        <Card style={{width: "100%"}}>
                            <Card.Body>
                                <Card.Title>
                                    Ваше койко-место
                                </Card.Title>
                                <Card.Text>
                                    <p style={{fontSize: "2em", textAlign: "center"}}><b>{client.fullNo}</b></p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <br/>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    <p><b>Оплачено до {moment(client.lastPay).format("DD/MM ddd")}</b></p>
                                </Card.Text>
                                <Button style={{width: "100%"}} onClick={() => props.openPanel({
                                    props: {
                                        client: client,
                                    },
                                    renderPanel: PanelAuth,
                                    title: "Оплата",
                                })}>Перейти к оплате</Button>
                            </Card.Body>
                        </Card>
                        <br/>
                        <Button style={{width: "100%"}} disabled>Правила проживания</Button>
                    <br/><br/>
                        <Button style={{width: "100%"}} disabled>Копия договора</Button>
                    <br/><br/>
                        <Button style={{width: "100%"}} disabled>Ваш администратор</Button>
                    <br/><br/>
                        <Button style={{width: "100%"}} disabled>О приложении</Button>
                        <br/>
                    </div>
                    : null
            }
        </div>
    };


    const PanelAuth = props => {
        const [client, setClient] = useState({});
        const [loadResult, setLoadResult] = useState("loading");
        const [period, setPeriod] = useState(7);
        const [days, setDays] = useState(7);
        const [sendResult, setSendResult] = useState("");

        const getData = () => {
            axios.post("/api/clients/openapi/where", {
                where: {
                    name: localStorage.getItem("name"),
                    id: Number(localStorage.getItem("id")),
                }
            }).then(data => {
                setClient(data.data[0]);
                setLoadResult("ok");
            })
        };

        useEffect(() => {
            if (loadResult === "loading") {
                getData();
            }
        }, [loadResult]);

        const handleChangeDays = (val) => {
            if (val > 6) {
                setDays(6);
            } else if (val < 1) {
                setDays(1);
            } else {
                setDays(val);
            }
        };

        const send = () => {
            setSendResult("loading");
            axios.post("/api/clients/openapi/pay", {days, name: client.name, placeId: client.place.id})
                .then((data) => {
                    if (data.data.paymentLink) {
                        window.open(data.data.paymentLink, "_blank");
                        setSendResult("");
                    } else {
                        setSendResult("errorPayment")
                    }
                })
        };

        return <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
            {
                loadResult === "ok"
                    ? <div className="centeredCard">
                        <br/>
                        <Card style={{width: "100%"}}>
                            <Card.Body>
                                <Card.Title>
                                    Ваше койко-место
                                </Card.Title>
                                <Card.Text>
                                    <p style={{fontSize: "2em", textAlign: "center"}}><b>{client.fullNo}</b></p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Row style={{width: "100%", padding: "10px", justifyContent: "center", margin: 0}} xs={3}>
                            <Col style={{padding: "1px"}}>
                                <Card style={{background: period === 1 ? "rgba(0,255,0,0.25)" : null}}
                                      onClick={() => {
                                          setPeriod(1);
                                          setDays(1)
                                      }}>
                                    <Card.Body>
                                        <Card.Text>
                                            <p>Сутки</p>
                                            <p><b>{client.place.room.hotel.priceDefault['1']}</b></p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col style={{padding: "1px"}}>
                                <Card style={{background: period === 7 ? "rgba(0,255,0,0.25)" : null}}
                                      onClick={() => {
                                          setPeriod(7);
                                          setDays(7)
                                      }}>
                                    <Card.Body>
                                        <Card.Text>
                                            <p>Неделя</p>
                                            <p><b>{client.place.room.hotel.priceDefault['7']}</b></p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col style={{padding: "1px"}}>
                                <Card style={{background: period === 30 ? "rgba(0,255,0,0.25)" : null}}
                                      onClick={() => {
                                          setPeriod(30);
                                          setDays(30)
                                      }}>
                                    <Card.Body>
                                        <Card.Text>
                                            <p>Месяц</p>
                                            <p><b>{client.place.room.hotel.priceDefault['30']}</b></p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        {
                            period === 1
                                ? <>
                                    <Card>
                                        <Card.Body>
                                            <Form.Group>
                                                <Form.Label>Кол-во суток</Form.Label>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    gap: "10px"
                                                }}>
                                                    <Button onClick={() => handleChangeDays(days - 1)}>-</Button>
                                                    <p style={{fontSize: "2em"}}><b>{days}</b></p>
                                                    <Button onClick={() => handleChangeDays(days + 1)}>+</Button>
                                                </div>
                                            </Form.Group>
                                        </Card.Body>
                                    </Card>
                                    <br/>
                                </>
                                : null
                        }
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Итог
                                </Card.Title>
                                <Card.Text>
                                    <p><b>Будет оплачено
                                        до: {moment(client.lastPay).add(getDays(days).days, getDays(days).type).format("DD/MM ddd")}</b>
                                    </p>
                                    <p><b>Стоимость: {getPrice(client, days)} ₽</b></p>
                                </Card.Text>
                                <Button style={{width: "100%"}} onClick={send}
                                        disabled={sendResult === "loading"}>{sendResult === "loading" ? "Ожидайте..." : "Перейти к оплате"}</Button>
                            </Card.Body>
                        </Card>
                    </div>
                    : null
            }
        </div>
    };

    const Panel0 = props => {
        const [page, setPage] = useState(0);
        const [name, setName] = useState(localStorage.getItem("clientPayName") ? localStorage.getItem("clientPayName") : "");
        const [loadResult, setLoadResult] = useState("loading");
        const [sendResult, setSendResult] = useState("");
        const [places, setPlaces] = useState([]);
        const [placeId, setPlaceId] = useState(0);
        const [days, setDays] = useState(7);
        const [searchPlace, setSearchPlace] = useState("");

        const getData = () => {
            axios.get("/api/places/openapi")
                .then(data => {
                    if (data.status === 403) {
                        setLoadResult("notAccess");
                    } else if (data.data === []) {
                        setLoadResult("notFound");
                    } else {
                        const p = data.data.filter(place => place.client);
                        setPlaces(p);
                        setPlaceId(localStorage.getItem("clientPayPlace") ? Number(localStorage.getItem("clientPayPlace")) : p[0].id);
                        setLoadResult("ok");
                    }
                })
        };

        const send = () => {
            setSendResult("loading");
            axios.post("/api/clients/openapi/where", {where: {name, placeId}})
                .then(data => {
                    if (data.data !== false) {
                        console.log(data.data);
                        setSendResult("ok");
                        localStorage.setItem("name", name);
                        localStorage.setItem("id", String(data.data[0].id));
                        window.location.reload();
                    }
                });
        };

        const filterPlace = (query, place, _index, exactMatch) => {
            const normalizedFullNo = place.fullNo.toLowerCase();
            const normalizedQuery = query.toLowerCase();

            if (exactMatch) {
                return normalizedFullNo === normalizedQuery;
            } else {
                return normalizedFullNo.indexOf(normalizedQuery) >= 0;
            }
        };

        useEffect(() => {
            if (loadResult === "loading") {
                getData();
            }
        }, [loadResult]);

        return <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <br/>
            {
                loadResult === "loading"
                    ? <>
                    </>
                    : null
            }
            {
                loadResult === "ok"
                    ? <div className="centeredCard">
                        <Form>
                            <Form.Group>
                                <Form.Label htmlFor="name-input">Имя</Form.Label>
                                <Form.Control
                                    id="name-input"
                                    defaultValue={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <Form.Text muted>Вводите полное имя</Form.Text>
                            </Form.Group>
                            <br/>
                            <Form.Group>
                                <Form.Label>Койко-место</Form.Label>
                                <Dropdown>
                                    <Dropdown.Toggle style={{width: "100%"}} variant="outline-secondary">
                                        {(places.find(p => p.id === placeId)).fullNo}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu style={{width: "100%", height: "200px", overflowY: "scroll"}}>
                                        <Form.Control value={searchPlace} onInput={(e) => setSearchPlace(e.target.value)}/>
                                        {
                                            places.filter(p => !searchPlace || p.fullNo.includes(searchPlace)).map(p => {
                                                return <Dropdown.Item
                                                    onClick={() => setPlaceId(p.id)}>{p.fullNo}</Dropdown.Item>
                                            })
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>
                            <br/>
                            <Button style={{width: "100%"}} onClick={send}
                                    disabled={sendResult === "loading"}>{sendResult !== "loading" ? "Вход" : "Ожидайте"}</Button>
                        </Form>
                    </div>
                    : null
            }
        </div>
    };

    const initialPanel = localStorage.getItem("name") ? {
        props: {},
        renderPanel: PanelClient,
        title: "Личный кабинет"
    } : {
        props: {},
        renderPanel: Panel0,
        title: "Вход в личный кабинет",
    };

    const [currentPanelStack, setCurrentPanelStack] = useState([initialPanel]);

    const addToPanelStack = React.useCallback(
        (newPanel) => setCurrentPanelStack(stack => [...stack, newPanel]),
        [],
    );

    const removeFromPanelStack = React.useCallback(() => setCurrentPanelStack(stack => stack.slice(0, -1)), []);

    console.log(currentPanelStack);

    return <div style={{width: "100vw", height: "100vh"}}>
        <PanelStack2
            className="stack"
            stack={currentPanelStack}
            onOpen={addToPanelStack}
            onClose={removeFromPanelStack}
        />
    </div>
}

export default App;
